import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  clearAiAssistantCurrentDialog,
  getAiAssistantHistory,
  getAiAssistantQuotas,
  getAiAssistantStatus,
  getDialogById,
} from '@api';

export const aiAssistantKeys = {
  status: ['aiAssistantStatus'],
  quotas: ['aiAssistantQuotas'],
  history: ['aiAssistantHistory'],
  dialog: ['aiAssistantDialog'],
};

export const useAiAssistantStatusQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: aiAssistantKeys.status,
    queryFn: () => getAiAssistantStatus(),
    enabled,
    staleTime: 0,
  });
};

export const useAiAssistantQuotasQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: aiAssistantKeys.quotas,
    queryFn: () => getAiAssistantQuotas(),
    enabled,
  });
};

export const useAiAssistantHistoryQuery = ({
  enabled,
  staleTime,
}: {
  enabled: boolean;
  staleTime?: number;
}) => {
  return useQuery({
    queryKey: aiAssistantKeys.history,
    queryFn: () => getAiAssistantHistory(),
    enabled,
    staleTime,
  });
};

export const useClearAiAssistantCurrentDialogMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: clearAiAssistantCurrentDialog,
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: [aiAssistantKeys.status],
      }),
  });
};

export const useAiAssistantGetDialogByIdQuery = ({
  enabled,
  dialogId,
}: {
  enabled: boolean;
  dialogId: string;
}) => {
  return useQuery({
    queryKey: [aiAssistantKeys.dialog, dialogId],
    queryFn: () => getDialogById(dialogId),
    enabled,
    staleTime: 0,
  });
};
